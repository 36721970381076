import { Component, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-training-library',
  templateUrl: './training-library.component.html',
  styleUrls: ['./training-library.component.scss']
})
export class TrainingLibraryComponent implements OnInit {

  constructor(       private elementRef: ElementRef
    ) { }
admin_guid_block:any;
user_guide:any;
isAdminSelected:boolean=true;

  ngOnInit(): void {
  this.admin_guid_block= this.elementRef.nativeElement.querySelector('#admin-guide');
  this.user_guide= this.elementRef.nativeElement.querySelector('#everyday-user-guide');


  }
  guideChange(tabname:any){
    if(tabname=='admin'){
      this.isAdminSelected=true;
      this.user_guide.style.display="none";
      this.admin_guid_block.style.display = 'block';

  }
  else if(tabname=='user'){
    this.isAdminSelected=false;
    this.admin_guid_block.style.display = 'none';
    this.user_guide.style.display="block";

  }

}
}