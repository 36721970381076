
        <div  class="container">
            <div class="row">
                <div class="col-md-12">
                    <div>
                        <label class="">
                            <div class="header-label">
                                Contact Us!
                            </div>
                            <div class="main-heading mt-4">Thank you for reaching out to our support page.</div>
                            <div class="mt-4 sub-heading"> Please send us an email with your support
                                question/product suggestion to <a
                                    href="mailto:himnappsupport@himn.health">himnappsupport@himn.health</a></div>
                        </label>
                    </div>
                    <div class="mt-2">
                        <label class="textBold">
                            Please include the following information:
                        </label>
                    </div>
                    <div>
                        <label class="text">
                            • <b>Operating System:</b> Microsoft Windows or MacOS along with the version of the OS
                        </label>
                    </div>
                    <div>
                        <label class="text">
                            • <b>Type of application:</b> iOS, Android, or Web Browser </label>
                        <div class="ml-5">
                            <label class="text">
                                o If Web Browser, please specify which browser and the version </label>
                        </div>
                    </div>
                    <div>
                        <label class="text">
                            • <b>Issue</b> you are Experiencing or <b>suggestion</b> for the product
                        </label>
                    </div>
                    <div class="mt-2">
                        <label class="text">
                            We will get back to you as soon as possible.
                        </label>
                    </div>
                    <div class="mt-2">
                        <label class="text">
                            Thank you for being part of Parawell
                        </label>
                    </div>
                    <div class="mt-2">
                        <label class="text">
                            <b>Support Team</b>
                        </label>
                    </div>
                </div>
            </div>
        </div>
