import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class ProviderService extends BaseService {
  public email: string;
  public userId: number;
  public firstname: string;
  public lastname: string;
  constructor(injector: Injector) {
    super(injector);
  }

  public getProviderSso(): any {
    const url = '/user/providerssourl';
    return this.get(url);
  }

  public getHeadsupSso(userDetails): any {
    const url = '/user/headsupssourl';
    return this.post(url, userDetails);
  }

  public getUserList(status): any {
    const url = '/Provider/list?status=' + status;
    return this.get(url);
  }

  public validateUsername(userName): any {
    const url = '/Provider/loginIdValidate?loginId=' + userName;
    return this.get(url);
  }

  public saveProvider(userdata): any {
    const url = '/Provider/create';
    return this.post(url, userdata);
  }

  public updateProvider(userdata): any {
    const url = '/Provider/update';
    return this.post(url, userdata);
  }

  public getuserDetailsbyid(id): any {
    const url = '/Provider/user/' + id;
    return this.get(url);
  }

  public getPatientList(search, itemsPerPage, currentPage): any {
    const url = '/Patient/list';
    return this.post(url, { search, itemsPerPage, currentPage });
  }


  // public createHeadsupUser(userId): any {
  //   const url = '/Provider/createheadsupuser?UserId=' + userId;
  //   return this.get(url);
  // }

  public createHeadsupUserInvite(userId, himnPlatform): any {
    const url = '/Patient/headsupPatientInvite?UserId=' + userId + '&himnPlatform=' + himnPlatform;
    return this.get(url);
  }

  public resendInvitation(userId): any {
    const url = '/Provider/resendheadsupinvitation?UserId=' + userId;
    return this.get(url);
  }

  public activateAndDeactivateUser(userId, status): any {
    const url = '/Provider/headsupuserstatuschange?UserId=' + userId + '&status=' + status;
    return this.get(url);
  }

  public inviteHeadsupUser(userId): any {
    const url = '/Provider/inviteheadsupuser?UserId=' + userId;
    return this.get(url);
  }

  public uploadFile(file, messageDeliveryTypeName: string): any {
    const selectedFile = new FormData();
    selectedFile.append('file', file);
    selectedFile.append('messageDeliveryTypeName', messageDeliveryTypeName);
    const url = '/patient/upload';
    return this.post(url, selectedFile);
  }

  public changePassword(password: string): any {
    let keyvalue: any = {};
    keyvalue.value = password;
    const url = '/User/changepassword';
    return this.put(url, keyvalue);
  }
  // public getClassifications()
  // {
  //   const url = '/Provider/classification';
  //   return this.get(url);
  // }
  // public getSpecialization(classification)
  // {
  //   let taxonomyDetails: any = {};
  //   taxonomyDetails.Classification = classification;
  //   const url = '/Provider/specialization';
  //   return this.post(url,taxonomyDetails);

  // }
  // public getTaxonomyDetails(id)
  // {
  //   const url = '/Provider/taxonomyDetails/' + id;
  //   return this.get(url);

  // }
  public saveUserAction(userActionInfo): any {
    const url = '/Provider/saveUserActionInfo';
    return this.post(url, userActionInfo);
  }

  public updatePatient(PatientInfo) {
    const url = '/patient/update';
    return this.post(url, PatientInfo);
  }
  public saveInterimPatient(PatientInfo): any {
    const url = '/patient/interim/create';
    return this.post(url, PatientInfo);
  }

  public practiceAdvanceCareStatus(): any {
    const url = '/practice/advanceCareStatus';
    return this.get(url);
  }

  public getPatientDetailsbyid(patientId) {
    const url = '/patient/getPatientById/' + patientId;
    return this.get(url);
  }

  public OnResetPassword(userName: string) {
    var data = { value: userName };
    const url = '/User/forgotpassword';
    return this.post(url, data);
  }

  public resendHimnInvitation(userId): any {
    const url = '/Patient/resendHimnInvitation?UserId=' + userId;
    return this.get(url);
  }
  public MessageDeliveryType() {
    const url = '/patient/messagedelivery';
    return this.get(url);
  }

  public getInvitationLogDetails(userId = 0, interimPatientId = 0) {
    const url = '/patient/invitation/log-details?UserId=' + userId + '&interimPatientId=' + interimPatientId;
    return this.get(url);
  }

  public inviteNonHimnPatients(data): any {
    const url = '/patient/inviteNonHimnPatient';
    return this.post(url, data);
  }

  public getHimnAccessToken(potentialClientId: any, potentialClientSecret: any, requestorId: any) {
    const url = `/GetHimnAccessToken?potentialClientId=${potentialClientId}&potentialClientSecret=${potentialClientSecret.toString()}&requestorId=${requestorId}`;
    return this.get(url);
  }

  public hasHealthGorillaEnabled() {
    const url = '/patient/hasHealthGorillaModule';
    return this.get(url);
  }

  public logAuditAction(data): any {
    const url = '/user/logauditaction';
    return this.post(url, data);
  }
}
