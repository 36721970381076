import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@environments/environment';

import { StorageService } from '@core/services/storage.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { User } from '@core/models/user';
import { Router } from '@angular/router';
import { Role } from '@core/models/role';
import jwt_decode from 'jwt-decode';
import { AppService } from '@core/services/app.service';

const storageKey = 'himn-provider';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;

  constructor(
    private appService: AppService,
    private router: Router,
    private http: HttpClient,
    private storageService: StorageService
  ) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(this.storageService.get(storageKey)));
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  public login(username: string, password: string, ipAddress: string): any {
    const role: string = 'Provider,Updox Admin,Staff';
    const application: string = 'Provider Portal';
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Application': application
    });
    let options = { headers: headers };
    return this.http.post<any>(`${environment.apiUrl}/user/authenticate`, { username, password, role, ipAddress }, options)
      .pipe(map(user => {
        this.storageService.add(storageKey, JSON.stringify(user));
        this.userSubject.next(user);
        return user;
      }),
        catchError(this.handleError)
      );
  }

  public logout(timedOut: boolean): any {
    const application: string = 'Provider Portal';
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Application': application
    });
    let options = { headers: headers };
    
    if (!timedOut)
      this.http.post(`${environment.apiUrl}/DashboardLogout`, { requestorid: this.userValue.publicUserId }, options).subscribe();

    this.appService.setUserLoggedIn(false);
    this.storageService.remove(storageKey);
    this.userSubject.next(null);
  }

  public getToken(): any {
    let token = this.userValue == null ? null : this.userValue.token;
    const tokenInfo = this.getDecodedAccessToken(token);
    if (tokenInfo && !tokenInfo.firstName.trim()) {
      token = null;
    }
    return tokenInfo == null ? null : token;
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }

  public isAdmin(): any {
    if (this.userValue.roles.indexOf(Role.UPDOXADMIN) !== -1)
      return true;
    else
      return false;
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      return throwError(new Error(errMessage));
    }
    return throwError(error || new Error('Server error'));
  }

  public isHeadsUpActive(): any {
    if (this.userValue.isHeadsUpActive)
      return true;
    else
      return false;
  }

  isUserLoggedIn(): any {
    if (this.getToken()) {
      return true;
    }
    return false;
  }

}
