import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  add(key: string, value: any, isEncode: boolean = true): void {
    this.remove(key);
    if (value && isEncode) {
      value = JSON.stringify(value);
      value = btoa(value);
    }
    else {
      value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
  }

  get<T>(key: string, isDecode: boolean = true): T {
    let value: string = localStorage.getItem(key);

    if (value && value !== 'undefined' && value !== 'null') {
      try {
        if (isDecode) {
          value = atob(value);
        }
        return <T>JSON.parse(value);
      }
      catch (ex) {
        return null;
      }
    }
    return null;
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }

  removeAll(): void {
    localStorage.clear();
  }
}
