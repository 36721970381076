import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/authentication/authentication.service';
import { AppService } from '@core/services/app.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'himn-provider-layout',
  templateUrl: './provider-layout.component.html',
  styleUrls: ['./provider-layout.component.scss']
})
export class ProviderLayoutComponent implements OnInit {
  @ViewChild('resetPassword', { static: false }) resetPassword: any;
  public isMenuExpanded: boolean = false;
  displayName: string = '';
  roles: string = '';
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private appService: AppService,
  ) { }

  ngOnInit(): void {
    this.displayName = this.authenticationService.userValue.displayName;
    this.roles = this.authenticationService.userValue.roleDisplayNames;
    this.appService.setUserLoggedIn(true); // for ng-ideal timeout
  }

  public logout(): void {
    this.authenticationService.logout(false);
    this.router.navigate(['/auth/logout']);
  }

  menutoggle(isMenuExpanded: boolean): void {
    this.isMenuExpanded = isMenuExpanded;
  }

  changePassword(): void {
    this.resetPassword.open();
  }

  onClickHelp() {
    //window.open(environment.trainingLibraryUrl);
    let leftPosition;
    let topPosition;
    const width = screen.width - 80;
    const height = screen.height - 80;
    // Allow for borders.
    leftPosition = (window.screen.width / 2) - ((width / 2));
    // Allow for title and status bars.
    topPosition = (window.screen.height / 2) - ((height / 2));
    // Open the window.
    // const url = environment.trainingLibraryUrl;
    const url ='training-library'

    // window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=300,width=800,height=800");
    window.open(url, 'Window2',
      'status=no,height=' + height + ',width=' + width + ',resizable=yes,left='
      + leftPosition + ',top=' + topPosition + ',screenX=' + leftPosition + ',screenY='
      + topPosition + ',toolbar=no,menubar=no,scrollbars=no,location=no,directories=no');
  }

}
