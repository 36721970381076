import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/authentication/authentication.service';

@Component({
  selector: 'himn-logout-confirmation',
  templateUrl: './logout-confirmation.component.html',
  styleUrls: ['./logout-confirmation.component.scss']
})
export class LogoutConfirmationComponent implements OnInit, OnDestroy {

  private element: any;

  constructor(
    private el: ElementRef,
    private router: Router,
    private authencationService: AuthenticationService
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    this.element.style.display = 'none';
    document.body.appendChild(this.element);
  }

  // remove self from modal when component is destroyed
  ngOnDestroy(): void {
    this.element.remove();
  }

  // open modal
  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('ra-modal-open');
  }

  // close modal
  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('ra-modal-open');
  }

  logout(): void {
    this.close();
    this.authencationService.logout(false);
    this.router.navigate(['/auth/logout']);
  }
}
