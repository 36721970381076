<div [ngClass]="{'is-expanded': isMenuExpanded}">
    <div class="side-nav" sideBar>
        <div class="side-nav-inner">
            <ul class="side-nav-menu scrollable">
                
                <!-- Leaving this in just in case we decide to reactivate it
                <li clas="nav-item"  routerLinkActive="selected-menu">
                    <a class="mrg-top-30" href="" [routerLink]="['/provider/updox']"
                        [ngbTooltip]="CommunicationCenter" container="body" placement="right">
                        <span class="icon-holder">
                            <img class="icon" src="../../../../../assets/images/menu/CommunicationCenter.svg">
                        </span>
                        <span class="title">Communication Center</span>
                    </a>
                </li>  
                -->
               
                <li clas="nav-item" routerLinkActive="selected-menu">
                    
                    <a class="mrg-top-30" href="" [routerLink]="['/provider/patients']"
                        [ngbTooltip]="PatientList" container="body" placement="right">
                        <span class="icon-holder">
                            <img class="icon" src="../../../../../assets/images/menu/PatientIcon.svg">
                        </span>
                        <span class="title" >Patient List</span>
                    </a>
                </li>
                <li clas="nav-item" routerLinkActive="selected-menu" *ngIf="isAdmin">
                    <a class="mrg-top-30" href="" [routerLink]="['/provider/admin']"
                    [ngbTooltip]="ProviderPortalUsers" container="body" placement="right">
                        <span class="icon-holder">
                            <img class="icon" src="../../../../../assets/images/menu/SettingsGear.svg">
                        </span>
                        <span class="title">Users</span>
                    </a>
                </li>

                <li style="position: absolute;bottom: 3rem;cursor: pointer;" clas="nav-item" routerLinkActive="open"
                [ngbTooltip]="Logout" container="body" placement="right" >
                    <a class="mrg-top-30 mb-1" (click)="onLogout()"
                    >
                        <span class="icon-holder">
                            <img class="icon" src="../../../../../assets/images/menu/LogoutCircle.svg">
                        </span>
                        <span class="title">Logout</span>
                    </a>
                </li>

                <li style="position: absolute;bottom: 0px;cursor: pointer;" clas="nav-item" routerLinkActive="open">
                    <a class="mrg-top-30" (click)="menuExpand()" *ngIf="!isMenuExpanded"
                    [ngbTooltip]="ShowIconNames" container="body" placement="right" tooltipClass="tooltip-class">
                        <span class="icon-holder">
                            <img  style="width: 22px !important;" height="26px" class="icon"
                                src="../../../../../assets/images/menu/Expand.svg">
                            

                        </span>
                        <span class="title"></span>
                    </a>
                    <a class="mrg-top-30" (click)="menuExpand()"  *ngIf="isMenuExpanded"
                    [ngbTooltip]="HideIconNames" container="body" placement="right" tooltipClass="tooltip-class">
                        <span class="icon-holder">
                            
                            <img style="width: 22px !important;" height="26px" class="icon"
                                src="../../../../../assets/images/menu/Collapse.svg">

                        </span>
                        <span class="title"></span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<himn-logout-confirmation #popup></himn-logout-confirmation>
<ng-template #CommunicationCenter>
    <div> Communication Center </div>

</ng-template>
<ng-template #PatientList>
    <div> Patient List </div>

</ng-template>
<ng-template #ProviderPortalUsers>
    <div> Users </div>

</ng-template>
<ng-template #Logout>
    <div> Logout </div>

</ng-template>
<ng-template #HideIconNames>
    <div> Hide Icon Names </div>

</ng-template>
<ng-template #ShowIconNames>
    <div> Show Icon Names </div>

</ng-template>