<div class="container">
    <div class="flex-component-wrapper">
        <div class="flex-component component1 component-media-library">
            <div class="media-column-wrapper">
                <div class="media-sidebar">
                    <div class="media-sidebar-events">
                        <div class="media-sidebar-event " [ngClass]="{'guide-selected':isAdminSelected}">

                            <h3 class="h5">


                                <a (click)="guideChange('admin')"
                                    [ngClass]="{'guide-selected-textcolor':isAdminSelected, 'guide-textcolor':!isAdminSelected}">Admin
                                    Guide</a>
                            </h3>
                        </div>
                        <div class="media-sidebar-event " [ngClass]="{'guide-selected':!isAdminSelected}">

                            <h3 class="h5">


                                <a (click)="guideChange('user')"
                                    [ngClass]="{'guide-selected-textcolor':!isAdminSelected,'guide-textcolor':isAdminSelected}">Everyday
                                    User Guide</a>
                            </h3>
                        </div>

                    </div>
                </div>
                <div class="media-main">
                    <div class="media-main-events">
                        <div class="media-main-event" id="admin-guide" style="display: block;">
                            <div class="media-main-entries">
                                <div class="media-main-entry ">
                                    <p>
                                        <a style="display: block; text-align: center;"
                                            href="https://healthinmotionnetwork.com/wp-content/uploads/2020/10/HIMN-Admin-User-Guide_v2.2_Final.pdf"
                                            target="_blank" rel="noopener">
                                            <img class="aligncenter mt-4" style="margin-bottom: 10px;" role="img"
                                                src="//healthinmotionnetwork.com/wp-content/uploads/2020/10/pdf-icon.png"
                                                alt="PDF icon" width="84" height="86"><span class="a-color"
                                                style="display: block;">Admin User Guide</span></a>
                                    </p>

                                </div>
                                <div class="media-main-entry ">
                                    <p><a style="display: block; text-align: center;"
                                            href="https://youtu.be/5lPTojZiD2Q" target="_blank"><img class="aligncenter mt-4"
                                                src="https://img.youtube.com/vi/5lPTojZiD2Q/0.jpg"
                                                style="margin-bottom: 10px;" role="img"
                                                alt="Step 1 - HIMN Admin Getting Started" width="90"><span 
                                                style="display: block;" class="a-color test ">[5:40] Step 1 – HIMN Admin Getting
                                                Started</span></a></p>
                                </div>
                                <div class="media-main-entry ">
                                    <p><a style="display: block; text-align: center;"
                                            href="https://youtu.be/UoV4BaQ5m4Q" target="_blank"><img class="aligncenter mt-4"
                                                src="http://img.youtube.com/vi/UoV4BaQ5m4Q/0.jpg"
                                                style="margin-bottom: 10px;" role="img" alt="Step 2 - Manage User"
                                                width="90"><span style="display: block;" class="a-color">[3:44] Step 2 – Manage
                                                User</span></a></p>
                                </div>
                                <div class="media-main-entry ">
                                    <p><a style="display: block; text-align: center;"
                                            href="https://youtu.be/TaQM1Wo6N9A" target="_blank"><img class="aligncenter mt-4"
                                                src="http://img.youtube.com/vi/TaQM1Wo6N9A/0.jpg"
                                                style="margin-bottom: 10px;" role="img" alt="Step 3 - Direct Trust"
                                                width="90"><span style="display: block;" class="a-color">[6:18] Step 3 – Direct
                                                Trust</span></a></p>
                                </div>
                                <div class="media-main-entry ">
                                    <p><a style="display: block; text-align: center;"
                                            href="https://youtu.be/MLvfGLyeI64" target="_blank"><img class="aligncenter mt-4"
                                                src="http://img.youtube.com/vi/MLvfGLyeI64/0.jpg"
                                                style="margin-bottom: 10px;" role="img" alt="Step 4 - Patient Portal"
                                                width="90"><span style="display: block;" class="a-color">[2:30] Step 4 – Patient Portal
                                                Configuration</span></a></p>
                                </div>
                                <div class="media-main-entry ">
                                    <p><a style="display: block; text-align: center;"
                                            href="https://youtu.be/dC6C_arrypU" target="_blank"><img class="aligncenter mt-4"
                                                src="http://img.youtube.com/vi/dC6C_arrypU/0.jpg"
                                                style="margin-bottom: 10px;" role="img" alt="Step 5 - Reminders"
                                                width="90"><span style="display: block;" class="a-color">[2:21] Step 5 –
                                                Reminders</span></a></p>
                                </div>
                                <div class="media-main-entry ">
                                    <p><a style="display: block; text-align: center;"
                                            href="https://youtu.be/VkPsA_wVWng" target="_blank"><img class="aligncenter mt-4"
                                                src="http://img.youtube.com/vi/VkPsA_wVWng/0.jpg"
                                                style="margin-bottom: 10px;" role="img" alt="Step 6 - Scheduling"
                                                width="90"><span style="display: block;" class="a-color">[6:04] Step 6 –
                                                Scheduling</span></a></p>
                                </div>

                            </div>
                        </div>
                        <div class="media-main-event" id="everyday-user-guide" style="display: none;">
                            <div class="media-main-entries">
                                <div class="media-main-entry ">
                                    <p><a style="display: block; text-align: center;"
                                            href="https://healthinmotionnetwork.com/wp-content/uploads/2020/10/HIMN-Everyday-User-Guide-1.1-Final.pdf"
                                            target="_blank" rel="noopener"><img class="aligncenter mt-4"
                                                style="margin-bottom: 10px;" role="img"
                                                src="//healthinmotionnetwork.com/wp-content/uploads/2020/10/pdf-icon.png"
                                                alt="PDF icon" width="84" height="86"><span class="a-color"
                                                style="display: block;">Everyday User Guide</span></a></p>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>



            </div>

        </div>

    </div>
</div>