import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/authentication/authentication.service';


@Component({
  selector: 'himn-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  public isMenuExpanded: boolean = false;
  isAdmin: boolean = false;
  @Output() menutoggle = new EventEmitter();
  @ViewChild('popup', { static: false }) popup: any;

  constructor(
    public router: Router,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.isAdmin = this.authenticationService.isAdmin();
  }
  menuExpand(): void {
    this.isMenuExpanded = !this.isMenuExpanded;
    this.menutoggle.emit(this.isMenuExpanded);
  }
  onLogout(): void {
    // this.router.navigate(['/auth/login']);
    this.popup.open();
  }
}
