import { Injectable, Injector } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  private baseUrl: string = environment.apiUrl;
  protected http: HttpClient;

  constructor(protected injector: Injector) {
    try {
      this.http = this.injector.get(HttpClient);
    } catch (error) {
      throw 'Please import HttpClientModule to the app.';
    }
  }

  protected get(url: string): any {
    return this.http.get(this.baseUrl + url);
  }
  
  protected getWithoutLoader(url: string) {
    return this.http.get(this.baseUrl + url, { params: new HttpParams().set("isHideLoader", "true") })
  }

  protected post(url: string, data: any): any {
    return this.http.post(this.baseUrl + url, data);
  }

  protected put(url: string, data: any): any {
    return this.http.put(this.baseUrl + url, data);
  }

  protected delete(url: string): any {
    return this.http.delete(this.baseUrl + url);
  }

}
