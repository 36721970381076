import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, ViewEncapsulation, OnInit, OnDestroy, Input } from '@angular/core';
import { PasswordValidator } from '@core/services/password.validator';
import { ProviderService } from '@services/provider.service';

@Component({
  selector: 'himn-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  @Input() title: string = '';
  @Input() isHideTitle: boolean = false;
  @Input() isHideCloseButton: boolean = false;
  @Input() isFlyoutOpen: boolean = false;
  private element: any;

  public message: string;
  public newPassword: string;
  public confirmPassword: string;
  public submitted: boolean = false;
  public showMessage: boolean = false;
  public status: boolean = false;
  public showLogin: boolean = false;
  public newPasswordType: boolean = false;
  public confirmPasswordType:boolean=false;

  constructor(
    private el: ElementRef,
    private providerService: ProviderService
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    document.body.appendChild(this.element);
  }
  // remove self from modal when component is destroyed
  ngOnDestroy(): void {
    this.element.remove();
  }

  // open modal
  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('reset-modal-open');
  }

  // close modal
  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('reset-modal-open');
  }

  
  toggleNewPassword() {
    this.newPasswordType = !this.newPasswordType
  }
  toggleConfirmPassword(){
    this.confirmPasswordType =! this.confirmPasswordType
  }

  onSubmit(): void {
    if (this.newPassword && this.newPassword === this.confirmPassword) {
      this.showMessage = false;
      this.showLogin = false;
      if (PasswordValidator.strong(this.newPassword).strong) {
        this.providerService.changePassword(this.newPassword).subscribe(
          (data: any) => {
            this.showMessage = true;
            this.status = true;
            this.showLogin = true;
            this.message = 'Your password has been changed successfully!';
          },
          (err: HttpErrorResponse) => {
            this.status = false;
            this.showMessage = true;
            this.message = 'Something went wrong. Please try again!';
          });
      }
      else {
        this.status = false;
        this.showMessage = true;
        this.message = 'Password must: <br />';
        if (!PasswordValidator.strong(this.newPassword).hasUpper) {
          this.message += '<li>Have at least one uppercase letter.</li>';
        }
        if (!PasswordValidator.strong(this.newPassword).hasLower) {
          this.message += '<li>Have at least one lowercase letter.</li>';
        }
        if (!PasswordValidator.strong(this.newPassword).hasNumber) {
          this.message += '<li>Have at least one number.</li>';
        }
        if (!PasswordValidator.strong(this.newPassword).hasSpecialChar) {
          this.message += '<li>Have at least one special character.</li>';
        }
        if (this.newPassword.length < 8) {
          this.message += '<li>Have at least 8 characters.</li>';
        }
      }
    }
    else {
      this.status = false;
      this.showMessage = true;
      this.message = 'Passwords did not match. Please try again!';
    }
  }

  onCancel(): void {
    this.close();
  }

}
