
export interface ValidationResult {
  strong: boolean;
  hasNumber: boolean;
  hasUpper: boolean;
  hasLower: boolean;
  hasSpecialChar: boolean;
  hasLength: boolean;
}

export class PasswordValidator {

  public static strong(value): ValidationResult {
    const hasNumber = /\d/.test(value);
    const hasUpper = /[A-Z]/.test(value);
    const hasLower = /[a-z]/.test(value);
    const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value);
    const hasLength = value.length < 8 ? false : true;
    const valid = hasNumber && hasUpper && hasLower && hasSpecialChar && hasLength;
    if (valid) {
      return { strong: true, hasNumber: true, hasUpper: true, hasLower: true, hasSpecialChar: true, hasLength: true };
    }
    else {
      return {
        strong: false, hasNumber: hasNumber, hasUpper: hasUpper, hasLower: hasLower,
        hasSpecialChar: hasSpecialChar, hasLength: hasLength
      };
    }
    return null;
  }
}
