<div class="app">
    <div class="layout">
        <!-- Page Container START -->
        <div class="page-container">

            <!-- Header START -->
            <div class="header">
                <div class=" row header-container">

                    <ul class=" col nav-left">
                        <li class="logo-container">
                            <div class="logo-img">
                                <img class="prov-img" src="../../../../assets/images/logo/parawell-logo.png">
                            </div>
                        </li>
                    </ul>
                    <div class="col">
                        <div class="user-container">
                            <div class="logo-container">

                                <div class="user-details mr-2 mt-1" style="float: left;">
                                    <div class="text-right">
                                        {{displayName}}
                                    </div>
                                    <div class="text-right">
                                        {{roles}}
                                    </div>
                                </div>
                                <div class="user-image" (click)="changePassword()">
                                    <img style="cursor: pointer;" src="../../../../assets/images/Avatar.svg">
                                </div>

                                <div class="help-img" (click)="onClickHelp()">
                                    <img width="28" height="30" src="../../../../assets/images/help.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Header END -->

            <!-- Content Wrapper START -->
            <himn-side-nav (menutoggle)="menutoggle($event)"></himn-side-nav>
            <div [style.margin-left]="isMenuExpanded ? '254px' : '68px'" class="layout-container">
                <router-outlet></router-outlet>
            </div>

            <!-- Footer START -->
            <footer class="content-footer ">
                <div class="logo-footer ">
                    <div>
                        By creating an account you agree to our <span
                            style="text-decoration: underline;cursor: pointer;">Terms of Service
                        </span> and <span style="text-decoration: underline;cursor: pointer;">Privacy
                            Policy</span>
                    </div>
                    <span id="siteseal" style="float: right; margin-top: -25px;"></span>
                </div>
            </footer>
            <!-- Footer END -->
        </div>
    </div>
</div>

<himn-reset-password #resetPassword></himn-reset-password>