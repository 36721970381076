
<div class="dialog-container"  >
    <div class="dialog-body dialog-width">
       
        <div class="row"  style="padding: 20px;padding-top: 55px;">  
            <div class="col-3" style="text-align: right;">
                <a >
                    <img src="../../../../../assets/images/Alert.svg" />
                </a>
            </div>       
            <div class="col-9" style="padding-top:15px;">
                Are you sure you wish to logout?
            </div>
            
        </div>
        <div class="row " style="margin-top:23px;cursor: pointer;" >
            <div class="col bordertop btn-pd"  (click)="close()" >
                <span>
                    No
                </span>
            </div>
            <div class="col btnyes btn-pd"  (click)="logout()">
                <span>
                    Yes
                </span>
            </div>

        </div>
    </div>
</div>


<div class="dialog-background"></div>





