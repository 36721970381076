import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse } from '@angular/common/http';
import { AuthenticationService } from '@core/authentication/authentication.service';
import { LoaderService } from '@core/loader/loader.service';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
    public ipAddress: string = "";
    constructor(
        private authenticationService: AuthenticationService,
        private loaderService: LoaderService
    ) { }

    intercept(req, next): any {
        if (!req.params.get('isHideLoader'))
            this.showLoader();
        const user = this.authenticationService.userValue;
        const isLoggedIn = user && user.token;
        const publicIp = require('public-ip');
        (async () => {
            this.ipAddress = await publicIp.v4();
        })();
        if (isLoggedIn) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${user.token}`,
                    IpAddress: this.ipAddress
                }
            });
        }

        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                    this.onEnd();
            }
        },
            (err: any) => {
                this.onEnd();
            }));
    }

    private onEnd(): void {
        this.hideLoader();
    }

    private showLoader(): void {
        this.loaderService.show();
    }

    private hideLoader(): void {
        this.loaderService.hide();
    }
}
