import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';
import { AuthLayoutComponent } from './core/layouts/auth-layout/auth-layout.component';
import { ProviderLayoutComponent } from './core/layouts/provider-layout/provider-layout.component';
import { SupportComponent } from './modules/auth/support/support.component';
import { TrainingLibraryComponent } from './modules/provider/training-library/training-library/training-library.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'provider',
    component: ProviderLayoutComponent,
    canActivate: [AuthGuard],

    children: [
      {
        path: '',
        loadChildren: () => import('./modules/provider/provider.module').then(m => m.ProviderModule)
      }
    ]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'himnappsupport',
        component: SupportComponent
      }
    ]
  },
  {
    path: 'training-library',
    component: TrainingLibraryComponent,
   
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
