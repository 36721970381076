<div class="app">
    <div class="layout">
        <!-- Page Container START -->
        <div class="page-container">
            <!-- Header START -->
            <div class="header">
                <div class="header-container">
                    <ul class="nav-left">
                        <li class="logo-container">
                            <div class="logo-img">
                                <img src="../../../../assets/images/logo/parawell-logo.png">
                            </div>
                        </li>
                    </ul>

                </div>
            </div>
            <!-- Header END -->

            <div class="sub-header sub-header-position">
                <span class="sub-header-font">Provider Portal</span>
            </div>


            <!-- navigation START -->

            <!-- navigation END -->

            <!-- Content Wrapper START -->


            <div class=" d-flex justify-content-center box ">
                <div class=" d-flex justify-content-center innerBox">
                    <router-outlet></router-outlet>
                </div>
            </div>

            <!-- Content Wrapper END -->
        </div>
    </div>
</div>