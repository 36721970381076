import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { ProviderLayoutComponent } from './layouts/provider-layout/provider-layout.component';
import { LoaderComponent } from '@core/loader';
import { AuthInterceptor } from '@core/interceptors/auth.interceptors';
import { SideNavComponent } from './layouts/provider-layout/side-nav/side-nav.component';
import { LogoutConfirmationComponent } from './layouts/provider-layout/logout-confirmation/logout-confirmation.component';
import { ResetPasswordComponent } from './layouts/provider-layout/reset-password/reset-password.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [AuthLayoutComponent, ProviderLayoutComponent, SideNavComponent,
    LogoutConfirmationComponent, LoaderComponent, ResetPasswordComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    NgbTooltipModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  exports: [
    LoaderComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. You should only import Core modules in the AppModule only.');
    }
  }
}
