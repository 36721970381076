<div class="modal-container" [class.modal-with-flyout]="isFlyoutOpen">
    <div class="modal-body modal-width">
        <div>
            <div *ngIf="!isHideTitle" style="display: inline-block;">
                <h4 class="model-title">{{title}}</h4>
            </div>
            <div class="modal-close" *ngIf="!isHideCloseButton">
                <a (click)="close()">
                    <img src="../../../assets/images/close.png" />
                </a>
            </div>
        </div>
        <div class="reset-container">
            <div class="reset-wrapper">
                <h1 class="mt-2">Change password</h1>
                <div class="reset-container">
                    <div class="form-group required">
                        <label class=" eamilLabel" for="name">New Password</label>
                        <div class="form-control editor txt-login  ">
                            <input [type]="newPasswordType ? 'text' : 'password'" autocomplete="false" readonly
                                onfocus="this.removeAttribute('readonly');" class="pwd-width" [(ngModel)]="newPassword"
                                name="newPassword" required>
                            <div *ngIf="newPasswordType ; else showNewPassword" class="password-type"
                                (click)="toggleNewPassword()">
                                <img src="../../../assets/images/hidepwd.png" />
                            </div>
                            <ng-template #showNewPassword>
                                <div class="password-type" (click)="toggleNewPassword()">
                                    <img src="../../../assets/images/showpwd.png" />
                                </div>
                            </ng-template>
                        </div>
                        <div class="mt-3">
                            <label class=" eamilLabel" for="name">Confirm Password</label>
                            <div class="form-control editor txt-login">
                                <input [type]="confirmPasswordType ? 'text' : 'password'" autocomplete="false"
                                    class="pwd-width" [(ngModel)]="confirmPassword" name="confirmPassword" readonly
                                    onfocus="this.removeAttribute('readonly');" required>
                                <div *ngIf="confirmPasswordType ; else showConfPassword" class="password-type"
                                    (click)="toggleConfirmPassword()">
                                    <img src="../../../assets/images/hidepwd.png" />
                                </div>
                                <ng-template #showConfPassword>
                                    <div class="password-type" (click)="toggleConfirmPassword()">
                                        <img src="../../../assets/images/showpwd.png" />
                                    </div>
                                </ng-template>
                            </div>
                        </div>

                    </div>

                    <button type="submit" class="btn-save-cancel btn-cancel mb-3  mr-2"
                        (click)="onCancel()">Cancel</button>
                    <button type="submit" class="btn-save-cancel btn-save mb-3" (click)="onSubmit()">Save</button>

                    <div class="alert" [ngClass]="{'alert-danger' : !status, 'alert-success' : status}"
                        *ngIf="showMessage">
                        <div [innerHTML]="message"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-background"></div>